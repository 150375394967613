import React from "react";
import Weather from "./Weather";
import RandomFunFact from "./RandomFunFact";
import DailyQuote from "./DailyQuote";

function Home() {
  return (
    <div>
      <h1>Welcome</h1>
      <Weather city="Vukovar" /> {""}
      <RandomFunFact />
      <DailyQuote />
    </div>
  );
}

export default Home;
