import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Redvoznje from "./Redvoznje";
import WorkingTimes from "./WorkingTimes";
import StoresPage from "./StoresPage";
import BanksPage from "./BanksPage";
import AirportsPage from "./AirportsPage";
import Footer from "./Footer";
import BusSchedule from "./BusSchedule";
import BusSchedule1 from "./BusSchedule1";
import BusSchedule2 from "./BusSchedule2";
import BusSchedule3 from "./BusSchedule3";
import BusSchedule4 from "./BusSchedule4";
import BusSchedule5 from "./BusSchedule5";
import Linija2 from "./Linija2";
import Linija3 from "./Linija3";
import Linija4 from "./Linija4";
import Linija5 from "./Linija5";
import Linija6 from "./Linija6";
import SwipeableBusSchedules from "./SwipeableBusSchedules";
import SwipeableBusSchedule from "./SwipeableBusSchedule";
import RandomFunFact from "./RandomFunFact";
import DailyQuote from "./DailyQuote";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/redvoznje" element={<Redvoznje />} />
          <Route path="/working-times" element={<WorkingTimes />} />
          <Route path="/working-times/stores" element={<StoresPage />} />
          <Route path="/working-times/banks" element={<BanksPage />} />
          <Route path="/working-times/airports" element={<AirportsPage />} />
          <Route path="/busschedule" element={<BusSchedule />} />
          <Route path="/busschedule1" element={<BusSchedule1 />} />
          <Route path="/busschedule2" element={<BusSchedule2 />} />
          <Route path="/busschedule3" element={<BusSchedule3 />} />
          <Route path="/busschedule4" element={<BusSchedule4 />} />
          <Route path="/busschedule5" element={<BusSchedule5 />} />
          <Route path="/linija2" element={<Linija2 />} />
          <Route path="/linija3" element={<Linija3 />} />
          <Route path="/linija4" element={<Linija4 />} />
          <Route path="/linija5" element={<Linija5 />} />
          <Route path="/linija6" element={<Linija6 />} />
          <Route
            path="/swipeablebusschedules"
            element={<SwipeableBusSchedules />}
          />
          <Route
            path="/swipeablebusschedule"
            element={<SwipeableBusSchedule />}
          />
          <Route path="/dailyquote" element={<DailyQuote />} />
          <Route path="/randomfunfact" element={<RandomFunFact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
