import React from "react";
import BusSchedule2 from "./BusSchedule2";

function Linija3() {
  return (
    <div>
      <h2>TRPINJSKA CESTA - NOVO GROBLJE</h2>
      <BusSchedule2 />
    </div>
  );
}

export default Linija3;
