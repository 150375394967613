import React, { useState, useEffect } from "react";
import axios from "axios";

const RandomFunFact = () => {
  const [fact, setFact] = useState("Loading a fun fact...");

  useEffect(() => {
    // Fetch a random fun fact
    const fetchFact = async () => {
      try {
        const response = await axios.get(
          "https://uselessfacts.jsph.pl/random.json?language=en"
        );
        setFact(response.data.text);
      } catch (error) {
        setFact("Failed to load a fun fact. Please try again!");
        console.error(error);
      }
    };

    fetchFact();
  }, []); // Runs only once when the component mounts

  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
      }}
    >
      <h3>Fun Fact</h3>
      <p>{fact}</p>
    </div>
  );
};

export default RandomFunFact;
