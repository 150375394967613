import React from "react";
import { Link } from "react-router-dom";
import "./Redvoznje.css"; // Import the CSS file

function Redvoznje() {
  return (
    <div className="redvoznje-container">
      <h2 className="redvoznje-heading">Gradski red vožnje</h2>
      <ul className="redvoznje-list">
        <li>
          <Link to="/services" className="redvoznje-link">
            DVANAEST REDARSTVENIKA - SAJMIŠTE
          </Link>
        </li>
        <li>
          <Link to="/linija2" className="redvoznje-link">
            SAJMIŠTE - DVANAEST REDARSTVENIKA
          </Link>
        </li>
        <li>
          <Link to="/linija3" className="redvoznje-link">
            TRPINJSKA CESTA - NOVO GROBLJE
          </Link>
        </li>
        <li>
          <Link to="/linija4" className="redvoznje-link">
            NOVO GROBLJE - TRPINJSKA CESTA
          </Link>
        </li>
        <li>
          <Link to="/linija5" className="redvoznje-link">
            BOROVO NASELJE - LUŽAC - VUKOVAR
          </Link>
        </li>
        <li>
          <Link to="/linija6" className="redvoznje-link">
            VUKOVAR - LUŽAC - BOROVO NASELJE
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Redvoznje;
