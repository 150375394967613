import React from "react";
import BusSchedule3 from "./BusSchedule3";

function Linija4() {
  return (
    <div>
      <h2>NOVO GROBLJE - BOROVO NASELJE - TRPINJSKA CESTA</h2>
      <BusSchedule3 />
    </div>
  );
}

export default Linija4;
