import React, { useState, useEffect } from "react";
import "./BusSchedule.css"; // CSS for custom styles

// Static bus schedule for each station (You can add more stations and times)
const busSchedule = [
  {
    station: "Dvanaest r.",
    times: [
      "5:25",
      "6:10",
      "7:10",
      "7:55",
      "8:40",
      "9:25",
      "10:10",
      "10:55",
      "11:40",
      "12:25",
      "13:10",
      "13:45",
      "15:25",
      "16:55",
      "18:25",
      "19:55",
    ],
  },
  {
    station: "Borovo N.",
    times: [
      "5:30",
      "6:15",
      "7:15",
      "8:00",
      "8:45",
      "9:30",
      "10:15",
      "11:00",
      "11:45",
      "12:30",
      "13:15",
      "14:00",
      "15:30",
      "17:00",
      "18:30",
      "20:00",
    ],
  },
  {
    station: "Centar",
    times: [
      "5:45",
      "6:30",
      "7:30",
      "8:15",
      "9:00",
      "9:45",
      "10:30",
      "11:15",
      "12:00",
      "12:45",
      "13:30",
      "14:15",
      "15:45",
      "17:15",
      "18:45",
      "20:15",
    ],
  },
  {
    station: "Sajmište",
    times: [
      "6:00",
      "6:45",
      "7:45",
      "8:30",
      "9:15",
      "10:00",
      "10:45",
      "11:30",
      "12:15",
      "13:00",
      "13:45",
      "14:30",
      "16:00",
      "17:30",
      "19:00",
      "20:30",
    ],
  },
];

const BusSchedule = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Function to get next three bus times
  const getNextThreeTimes = (times) => {
    const current = currentTime.getHours() * 60 + currentTime.getMinutes();
    const upcomingTimes = times
      .map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Convert time to total minutes since midnight
      })
      .filter((time) => time > current); // Filter out past times

    return upcomingTimes.slice(0, 3); // Return the next three upcoming times
  };

  // Function to format time in HH:MM format
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="bus-schedule-container">
      {busSchedule.map((station, index) => {
        const nextThreeTimes = getNextThreeTimes(station.times);
        return (
          <div key={index} className="bus-table">
            <h2>{station.station}</h2>
            <table>
              <tbody>
                {station.times.map((time, idx) => {
                  const [hours, minutes] = time.split(":").map(Number);
                  const timeInMinutes = hours * 60 + minutes;
                  const isNextBus = nextThreeTimes.includes(timeInMinutes);

                  return (
                    <tr key={idx} className={isNextBus ? "highlight" : ""}>
                      <td>{time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default BusSchedule;
