import React, { useState, useEffect } from "react";
import "./BusSchedule.css"; // CSS for custom styles

// Static bus schedule for each station (You can add more stations and times)
const busSchedule3 = [
  {
    station: "Novo groblje",
    times: [
      "5:30",
      "6:00",
      "6:30",
      "6:45",
      "7:20",
      "8:05",
      "8:50",
      "9:35",
      "10:20",
      "11:05",
      "11:50",
      "12:35",
      "13:20",
      "14:05",
      "15:10",
      "16:30",
      "18:00",
      "19:30",
      "21:00",
    ],
  },
  {
    station: "Centar",
    times: [
      "5:35",
      "6:10",
      "6:40",
      "6:55",
      "7:30",
      "9:00",
      "9:00",
      "9:45",
      "10:30",
      "11:15",
      "12:00",
      "12:45",
      "13:30",
      "14:15",
      "15:20",
      "16:40",
      "18:10",
      "19:40",
      "21:10",
    ],
  },
  {
    station: "Borovo N.",
    times: [
      "5:50",
      "6:25",
      "6:55",
      "7:10",
      "7:45",
      "9:15",
      "9:15",
      "10:00",
      "10:45",
      "11:30",
      "12:15",
      "13:00",
      "13:45",
      "14:30",
      "15:35",
      "16:55",
      "18:25",
      "19:55",
      "21:25",
    ],
  },
  {
    station: "Lipovača",
    times: [
      "6:00",
      "/",
      "/",
      "7:20",
      "/",
      "/",
      "/",
      "10:10",
      "/",
      "/",
      "12:25",
      "13:10",
      "13:55",
      "/",
      "15:45",
      "17:05",
      "/",
      "20:05",
      "/",
    ],
  },
  {
    station: "Trpinjska c.",
    times: [
      "/",
      "6:35",
      "7:55",
      "/",
      "/",
      "9:25",
      "9:25",
      "/",
      "10:55",
      "11:40",
      "/",
      "14:05",
      "14:35",
      "/",
      "/",
      "18:35",
      "/",
      "21:30",
    ],
  },
];

const BusSchedule3 = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Function to get next three bus times
  const getNextThreeTimes = (times) => {
    const current = currentTime.getHours() * 60 + currentTime.getMinutes();
    const upcomingTimes = times
      .map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Convert time to total minutes since midnight
      })
      .filter((time) => time > current); // Filter out past times

    return upcomingTimes.slice(0, 3); // Return the next three upcoming times
  };

  // Function to format time in HH:MM format
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="bus-schedule-container">
      {busSchedule3.map((station, index) => {
        const nextThreeTimes = getNextThreeTimes(station.times);
        return (
          <div key={index} className="bus-table">
            <h2>{station.station}</h2>
            <table>
              <tbody>
                {station.times.map((time, idx) => {
                  const [hours, minutes] = time.split(":").map(Number);
                  const timeInMinutes = hours * 60 + minutes;
                  const isNextBus = nextThreeTimes.includes(timeInMinutes);

                  return (
                    <tr key={idx} className={isNextBus ? "highlight" : ""}>
                      <td>{time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default BusSchedule3;
