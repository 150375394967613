// SwipeableBusSchedules.js
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

// Import the six bus schedule components
import BusSchedule1 from "./BusSchedule1";
import BusSchedule2 from "./BusSchedule2";
import BusSchedule3 from "./BusSchedule3";
import BusSchedule4 from "./BusSchedule4";
import BusSchedule5 from "./BusSchedule5";
import BusSchedule from "./BusSchedule";

const busSchedules = [
  <BusSchedule key="1" />,
  <BusSchedule1 key="2" />,
  <BusSchedule2 key="3" />,
  <BusSchedule3 key="4" />,
  <BusSchedule4 key="5" />,
  <BusSchedule5 key="6" />,
];

const SwipeableBusSchedules = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle swipe left (to go to the next schedule)
  const handleNext = () => {
    if (currentIndex < busSchedules.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handle swipe right (to go to the previous schedule)
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Swipeable handlers
  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allow mouse swipe as well
  });

  return (
    <div {...handlers} style={{ textAlign: "center" }}>
      {/* Render the current bus schedule */}
      <div>{busSchedules[currentIndex]}</div>

      {/* Optional: Buttons to navigate left or right */}
      <div style={{ marginTop: "20px" }}>
        <button onClick={handlePrev} disabled={currentIndex === 0}>
          Prev
        </button>
        <button
          onClick={handleNext}
          disabled={currentIndex === busSchedules.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SwipeableBusSchedules;
