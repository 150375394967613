import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./SwipeableBusSchedule.css";

const busLines = [
  {
    line: "SAJMIŠTE - DVANAEST REDARSTVENIKA",
    stops: [
      {
        station: "SAJMIŠTE",
        times: [
          [
            "5:30",
            "6:15",
            "7:00",
            "7:45",
            "8:30",
            "9:15",
            "10:00",
            "10:45",
            "11:30",
            "12:15",
            "13:00",
            "13:45",
            "14:30",
            "16:00",
            "17:30",
            "19:00",
            "20:30",
          ],
        ],
      },
      {
        station: "CENTAR",
        times: [
          [
            "5:35",
            "6:25",
            "7:10",
            "7:55",
            "8:40",
            "9:25",
            "10:10",
            "10:55",
            "11:40",
            "12:25",
            "13:10",
            "13:55",
            "14:40",
            "16:10",
            "17:40",
            "19:10",
            "20:40",
          ],
        ],
      },
      {
        station: "BOROVO NASELJE",
        times: [
          [
            "5:45",
            "6:40",
            "7:25",
            "8:10",
            "8:55",
            "9:40",
            "10:25",
            "11:10",
            "11:55",
            "12:40",
            "13:25",
            "14:10",
            "14:45",
            "16:25",
            "17:55",
            "19:25",
            "20:55",
          ],
        ],
      },
      {
        station: "DVANAEST REDARSTVENIKA",
        times: [
          [
            "5:50",
            "6:45",
            "7:30",
            "8:15",
            "9:00",
            "9:45",
            "10:30",
            "11:15",
            "12:00",
            "12:45",
            "13:30",
            "14:15",
            "14:50",
            "16:30",
            "18:00",
            "19:30",
            "21:00",
          ],
        ],
      },
    ],
  },
  {
    line: "DVANAEST REDARSTVENIKA - SAJMIŠTE",
    stops: [
      {
        station: "DVANAEST REDARSTVENIKA",
        times: [
          [
            "5:25",
            "6:10",
            "7:10",
            "7:55",
            "8:40",
            "9:25",
            "10:10",
            "10:55",
            "11:40",
            "12:25",
            "13:10",
            "13:45",
            "15:25",
            "16:55",
            "18:25",
            "19:55",
          ],
        ],
      },
      {
        station: "BOROVO NASELJE",
        times: [
          [
            "5:30",
            "6:15",
            "7:15",
            "8:00",
            "8:45",
            "9:30",
            "10:15",
            "11:00",
            "11:45",
            "12:30",
            "13:15",
            "14:00",
            "15:30",
            "17:00",
            "18:30",
            "20:00",
          ],
        ],
      },
      {
        station: "CENTAR",
        times: [
          [
            "5:45",
            "6:30",
            "7:30",
            "8:15",
            "9:00",
            "9:45",
            "10:30",
            "11:15",
            "12:00",
            "12:45",
            "13:30",
            "14:15",
            "15:45",
            "17:15",
            "18:45",
            "20:15",
          ],
        ],
      },
      {
        station: "SAJMIŠTE",
        times: [
          [
            "6:00",
            "6:45",
            "7:45",
            "8:30",
            "9:15",
            "10:00",
            "10:45",
            "11:30",
            "12:15",
            "13:00",
            "13:45",
            "14:30",
            "16:00",
            "17:30",
            "19:00",
            "20:30",
          ],
        ],
      },
    ],
  },
];

const SwipeableBusSchedule = () => {
  const [lineIndex, setLineIndex] = useState(0); // Track current bus line
  const [stopIndex, setStopIndex] = useState(0); // Track current stop within a line
  const [timeSetIndex, setTimeSetIndex] = useState(0); // Track current time set for the stop

  const currentLine = busLines[lineIndex];
  const currentStop = currentLine.stops[stopIndex];

  // Swipe left: Go to the next bus line
  const handleSwipeLeft = () => {
    setLineIndex((prevIndex) =>
      prevIndex === busLines.length - 1 ? 0 : prevIndex + 1
    );
    setStopIndex(0); // Reset to the first stop of the new line
    setTimeSetIndex(0); // Reset to the first time set
  };

  // Swipe right: Go to the previous bus line
  const handleSwipeRight = () => {
    setLineIndex((prevIndex) =>
      prevIndex === 0 ? busLines.length - 1 : prevIndex - 1
    );
    setStopIndex(0); // Reset to the first stop of the new line
    setTimeSetIndex(0); // Reset to the first time set
  };

  // Swipe up: Show next set of times for the current stop
  const handleSwipeUp = () => {
    setTimeSetIndex((prevIndex) =>
      prevIndex === currentStop.times.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Swipe down: Show previous set of times for the current stop
  const handleSwipeDown = () => {
    setTimeSetIndex((prevIndex) =>
      prevIndex === 0 ? currentStop.times.length - 1 : prevIndex - 1
    );
  };

  // Swipe up to go to the next stop within the same line
  const handleNextStop = () => {
    setStopIndex((prevIndex) =>
      prevIndex === currentLine.stops.length - 1 ? 0 : prevIndex + 1
    );
    setTimeSetIndex(0); // Reset to first time set when changing stops
  };

  // Swipe down to go to the previous stop within the same line
  const handlePreviousStop = () => {
    setStopIndex((prevIndex) =>
      prevIndex === 0 ? currentLine.stops.length - 1 : prevIndex - 1
    );
    setTimeSetIndex(0); // Reset to first time set when changing stops
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    onSwipedUp: handleNextStop,
    onSwipedDown: handlePreviousStop,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // For testing on desktop
  });

  return (
    <div className="swipe-container" {...handlers}>
      <div className="swipe-content">
        <h2>{currentLine.line}</h2>
        <h3>{currentStop.station}</h3>
        <ul>
          {currentStop.times[timeSetIndex].map((time, index) => (
            <li key={index}>{time}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SwipeableBusSchedule;
