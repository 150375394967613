import React, { useState, useEffect } from "react";
import axios from "axios";

const DailyQuote = () => {
  const [quote, setQuote] = useState("Loading a daily quote...");
  const [author, setAuthor] = useState("");

  useEffect(() => {
    // Fetch a random quote
    const fetchQuote = async () => {
      try {
        const response = await axios.get("https://api.quotable.io/random");
        setQuote(response.data.content);
        setAuthor(response.data.author);
      } catch (error) {
        setQuote("Failed to load the quote. Please try again!");
        setAuthor("");
        console.error(error);
      }
    };

    fetchQuote();
  }, []); // Runs only once when the component mounts

  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f0f8ff",
        textAlign: "center",
      }}
    >
      <h3>Daily Quote</h3>
      <p>"{quote}"</p>
      <small>— {author}</small>
    </div>
  );
};

export default DailyQuote;
