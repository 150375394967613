import React from "react";
import SwipeableBusSchedules from "./SwipeableBusSchedules";

function Services() {
  return (
    <div>
      <h2>DVANAEST REDARSTVENIKA - BOROVO NASELJE - SAJMIŠTE</h2>
      <SwipeableBusSchedules />
    </div>
  );
}

export default Services;
