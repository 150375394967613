import React from "react";
import BusSchedule4 from "./BusSchedule4";

function Linija5() {
  return (
    <div>
      <h2>BOROVO NASELJE - LUŽAC - VUKOVAR</h2>
      <BusSchedule4 />
    </div>
  );
}

export default Linija5;
