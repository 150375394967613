import React from "react";
import BusSchedule5 from "./BusSchedule5";

function Linija6() {
  return (
    <div>
      <h2>VUKOVAR - LUŽAC - BOROVO NASELJE</h2>
      <BusSchedule5 />
    </div>
  );
}

export default Linija6;
