import React, { useState, useEffect } from "react";
import "./BusSchedule.css"; // CSS for custom styles

// Static bus schedule for each station (You can add more stations and times)
const busSchedule5 = [
  {
    station: "Dom zdravlja",
    times: ["7.20", "11.05", "13.20", "/", "/"],
  },
  {
    station: "Tržnica",
    times: ["7.24", "11.09", "13.24", "15.15", "19.30"],
  },
  {
    station: "Bolnica",
    times: ["7.28", "11.13", "13.28", "/", "/"],
  },
  {
    station: "Lužac",
    times: ["7.32", "11.17", "13.32", "15.20", "19.35"],
  },
  {
    station: "Radnički dom",
    times: ["7.35", "11.20", "13.35", "/", "/"],
  },
];

const BusSchedule5 = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Function to get next three bus times
  const getNextThreeTimes = (times) => {
    const current = currentTime.getHours() * 60 + currentTime.getMinutes();
    const upcomingTimes = times
      .map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Convert time to total minutes since midnight
      })
      .filter((time) => time > current); // Filter out past times

    return upcomingTimes.slice(0, 3); // Return the next three upcoming times
  };

  // Function to format time in HH:MM format
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="bus-schedule-container">
      {busSchedule5.map((station, index) => {
        const nextThreeTimes = getNextThreeTimes(station.times);
        return (
          <div key={index} className="bus-table">
            <h2>{station.station}</h2>
            <table>
              <tbody>
                {station.times.map((time, idx) => {
                  const [hours, minutes] = time.split(":").map(Number);
                  const timeInMinutes = hours * 60 + minutes;
                  const isNextBus = nextThreeTimes.includes(timeInMinutes);

                  return (
                    <tr key={idx} className={isNextBus ? "highlight" : ""}>
                      <td>{time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default BusSchedule5;
