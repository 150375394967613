import React from "react";
import BusSchedule1 from "./BusSchedule1";

function Linija2() {
  return (
    <div>
      <h2>SAJMIŠTE - BOROVO NASELJE - DVANAEST REDARSTVENIKA</h2>
      <BusSchedule1 />
    </div>
  );
}

export default Linija2;
