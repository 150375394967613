import React, { useState, useEffect } from "react";
import "./BusSchedule.css"; // CSS for custom styles

// Static bus schedule for each station (You can add more stations and times)
const busSchedule4 = [
  {
    station: "Radnički dom",
    times: ["7.05", "9.00", "12.00"],
  },
  {
    station: "Lužac",
    times: ["7.10", "9.05", "12.05"],
  },
  {
    station: "Bolnica",
    times: ["7.15", "9.10", "12.10"],
  },
  {
    station: "Tržnica",
    times: ["7.17", "9.12", "12.12"],
  },
  {
    station: "Dom zdravlja",
    times: ["7.19", "9.15", "12.15"],
  },
];

const BusSchedule4 = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Function to get next three bus times
  const getNextThreeTimes = (times) => {
    const current = currentTime.getHours() * 60 + currentTime.getMinutes();
    const upcomingTimes = times
      .map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Convert time to total minutes since midnight
      })
      .filter((time) => time > current); // Filter out past times

    return upcomingTimes.slice(0, 3); // Return the next three upcoming times
  };

  // Function to format time in HH:MM format
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="bus-schedule-container">
      {busSchedule4.map((station, index) => {
        const nextThreeTimes = getNextThreeTimes(station.times);
        return (
          <div key={index} className="bus-table">
            <h2>{station.station}</h2>
            <table>
              <tbody>
                {station.times.map((time, idx) => {
                  const [hours, minutes] = time.split(":").map(Number);
                  const timeInMinutes = hours * 60 + minutes;
                  const isNextBus = nextThreeTimes.includes(timeInMinutes);

                  return (
                    <tr key={idx} className={isNextBus ? "highlight" : ""}>
                      <td>{time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default BusSchedule4;
